define('ember-accordion/components/accordion-item', ['exports', 'ember', 'ember-accordion/templates/components/accordion-item'], function (exports, _ember, _emberAccordionTemplatesComponentsAccordionItem) {
  var computed = _ember['default'].computed;

  var AccordionItemComponent = _ember['default'].Component.extend({
    layout: _emberAccordionTemplatesComponentsAccordionItem['default'],
    classNames: ["AccordionItem"],

    itemId: computed(function () {
      return this.elementId;
    }),

    // item is active if there is an item in activeItems matching 'itemId'
    isActive: computed.notEmpty('ownActiveItem'),
    ownActiveItem: computed('activeItems.@each.id', 'itemId', function () {
      return this.get('activeItems').findBy('id', this.get('itemId'));
    }),

    activePanel: computed('isActive', 'ownActiveItem.panel', function () {
      if (!this.get('isActive')) {
        return null;
      }

      return this.get('ownActiveItem.panel');
    }),

    actions: {
      togglePanel: function togglePanel(panelName) {
        this.get('toggle')(this.get('itemId'), panelName);
      }
    }
  });

  AccordionItemComponent.reopenClass({
    positionalParams: ['activeItems']
  });

  exports['default'] = AccordionItemComponent;
});