define("@ember-decorators/argument/-private/resolve-validator", ["exports", "@ember-decorators/argument/-private/validators/any", "@ember-decorators/argument/-private/validators/-base", "@ember-decorators/argument/-private/validators/instance-of", "@ember-decorators/argument/-private/validators/type-match", "@ember-decorators/argument/-private/validators/value-match", "@ember-decorators/argument/-private/combinators/index"], function (_exports, _any, _base, _instanceOf, _typeMatch, _valueMatch, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = resolveValidator;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var primitiveTypeValidators = {
    any: new _any.default('any'),
    object: (0, _index.not)((0, _index.or)(_typeMatch.BOOLEAN, _typeMatch.NUMBER, _typeMatch.STRING, _typeMatch.SYMBOL, _valueMatch.NULL, _valueMatch.UNDEFINED)),
    boolean: _typeMatch.BOOLEAN,
    number: _typeMatch.NUMBER,
    string: _typeMatch.STRING,
    symbol: _typeMatch.SYMBOL,
    null: _valueMatch.NULL,
    undefined: _valueMatch.UNDEFINED
  };
  function resolveValidator(type) {
    if (type === null) {
      return _valueMatch.NULL;
    } else if (type === undefined) {
      return _valueMatch.UNDEFINED;
    } else if (type instanceof _base.default) {
      return type;
    } else if (typeof type === 'function' || _typeof(type) === 'object') {
      // We allow objects for certain classes in IE, like Element, which have typeof 'object' for some reason
      return new _instanceOf.default(type);
    } else if (typeof type === 'string') {
      (true && !(primitiveTypeValidators[type] !== undefined) && Ember.assert("Unknown primitive type received: ".concat(type), primitiveTypeValidators[type] !== undefined));
      return primitiveTypeValidators[type];
    } else {
      (true && !(false) && Ember.assert("Types must either be a primitive type string, class, validator, or null or undefined, received: ".concat(type), false));
    }
  }
});