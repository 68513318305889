define('ember-cli-stripe/services/stripe', ['exports', 'ember-cli-stripe/utils/configuration-options', 'ember-invoke-action'], function (exports, _configurationOptions, _emberInvokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      getWithDefault = Ember.getWithDefault,
      copy = Ember.copy,
      typeOf = Ember.typeOf,
      RSVP = Ember.RSVP,
      guidFor = Ember.guidFor,
      isBlank = Ember.isBlank,
      deprecate = Ember.deprecate;
  exports.default = Service.extend({

    /*
     * Registers a component as the current target of this service.
     * When the first {{stripe-checkout}} component is registered we load
     * the StripeCheckout js lib.
     *
     * @public
     */
    registerComponent: function registerComponent(component) {
      this._alive[guidFor(component)] = {
        component: component
      };

      // Load StripeCheckout js lib
      this._loadStripeJs();
    },


    /**
     * Unregisters a component.
     *
     * @public
     */
    unregisterComponent: function unregisterComponent(component) {
      delete this._alive[guidFor(component)];
    },


    /*
     * Open opens the StripeCheckout payment modal.
     *
     * @public
     */
    open: function open(component) {
      var _this = this;

      this._stripeScriptPromise.then(function () {
        var config = _this._stripeConfig(component);
        var stripeHandler = _this._stripeHandler(component);
        stripeHandler.open(config);
      });
    },


    /*
     * Close closes the StripeCheckout payment modal.
     * @public
     */
    close: function close(component) {
      var stripeHandler = this._stripeHandler(component);
      stripeHandler.close();
    },
    init: function init() {
      this._super.apply(this, arguments);

      this._alive = {};
      this._scriptLoaded = false;
      this._scriptLoading = false;
    },


    /**
     * Looks for any Stripe config options on the component.
     */
    _componentStripeConfig: function _componentStripeConfig(component) {
      var componentOptions = component.getProperties(_configurationOptions.default);
      return this._cleanupOptions(componentOptions);
    },


    /**
     * Stripe config options with env configs merged with the ones provided through
     * the component.
     */
    _stripeConfig: function _stripeConfig(component) {
      var stripeConfig = getWithDefault(this, 'stripeConfig', {});
      var options = copy(stripeConfig);
      // Support for Ember <= 2.4 (when assign was introduced)
      var assign = Ember.assign || Ember.merge;
      assign(options, this._componentStripeConfig(component));

      return this._cleanupOptions(options);
    },
    _stripeHandler: function _stripeHandler(component) {
      var componentGuid = guidFor(component);
      if ('handler' in this._alive[componentGuid]) {
        return this._alive[componentGuid]['handler'];
      }

      var stripeConfig = this._stripeConfig(component);
      if (!('key' in stripeConfig)) {
        throw new Error('[ember-cli-stripe] Missing required `key` param');
      }

      var handler = StripeCheckout.configure({
        key: stripeConfig.key,
        token: function token() {
          _emberInvokeAction.invokeAction.apply(undefined, [component, 'onToken'].concat(Array.prototype.slice.call(arguments)));

          // Add deprecation for previous `action` callback
          if (!isBlank(component.attrs.action)) {
            deprecate('Using `action` callback is deprecated and will be removed in future versions. Please use `onToken` with a closure action instead', false, { id: 'ember-cli-stripe.action-callback', until: '1.1.0' });

            _emberInvokeAction.invokeAction.apply(undefined, [component, 'action'].concat(Array.prototype.slice.call(arguments)));
          }
        },
        opened: function opened() {
          (0, _emberInvokeAction.invokeAction)(component, 'onOpened');
        },
        closed: function closed() {
          (0, _emberInvokeAction.invokeAction)(component, 'onClosed');
        }
      });

      this._alive[componentGuid]['handler'] = handler;

      return handler;
    },
    _loadStripeJs: function _loadStripeJs() {
      var _this2 = this,
          _arguments = arguments;

      if (this._scriptLoaded || this._scriptLoading) {
        return this._stripeScriptPromise;
      }

      this._scriptLoading = true;

      var script = document.createElement('script');
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.async = true;
      script.type = 'text/javascript';

      this._stripeScriptPromise = new RSVP.Promise(function (resolve, reject) {
        script.onload = function () {
          _this2._scriptLoaded = true;
          resolve();
          _this2.onScriptLoaded();
        };
        script.onerror = function () {
          _this2._invokeAction.apply(_this2, ['onStripeLoadError'].concat(Array.prototype.slice.call(_arguments)));
          reject();
        };

        document.body.appendChild(script);
      });

      return this._stripeScriptPromise;
    },
    onScriptLoaded: function onScriptLoaded() {},
    onStripeLoadError: function onStripeLoadError() {},
    _cleanupOptions: function _cleanupOptions(options) {
      var cleanedOptions = {};
      for (var key in options) {
        if (typeOf(options[key]) !== 'undefined') {
          cleanedOptions[key] = options[key];
        }
      }

      return cleanedOptions;
    }
  });
});