define('memory-scroll/components/memory-scroll', ['exports', 'memory-scroll/components/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    targetElement: function targetElement() {
      return this.element;
    }
  });
});