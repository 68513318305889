define('ember-windoc/services/windoc', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Service = Ember.Service,
      typeOf = Ember.typeOf,
      Evented = Ember.Evented,
      next = Ember.run.next;


  var WINDOW_PROPERTIES = ['innerHeight', 'innerWidth', 'outerHeight', 'outerWidth', 'screenX', 'screenY'];

  var PROPERTIES_TO_CHANGE_ON_INTERVAL = ['screenX', 'screenY', 'scrollLeft', 'scrollTop'];

  var PROPERTIES_TO_CHANGE_ON_WINDOW_RESIZE = ['innerWidth', 'innerHeight', 'outerWidth', 'outerHeight', 'scrollHeight', 'scrollWidth', 'scrollLeft', 'scrollTop', 'clientWidth', 'clientHeight', 'screenY', 'screenX'];

  var FAKE_WINDOW = {
    addEventListener: function addEventListener() {},
    removeEventListener: function removeEventListener() {},
    requestAnimationFrame: function requestAnimationFrame() {},
    cancelAnimationFrame: function cancelAnimationFrame() {}
  };

  var serviceCfg = {
    w: typeof FastBoot === 'undefined' ? window : FAKE_WINDOW,

    init: function init() {
      this._super.apply(this, arguments);
      this.get('w').addEventListener('resize', this._onWindowResize.bind(this));
      this._refreshPollLoop();
    },
    willDestroy: function willDestroy() {
      this.get('w').cancelAnimationFrame(this._rpid);
      this.get('w').removeEventListener('resize', this._onWindowResize);
    },
    _onWindowResize: function _onWindowResize(evt) {
      var _this = this;

      PROPERTIES_TO_CHANGE_ON_WINDOW_RESIZE.forEach(function (prop) {
        next(function () {
          _this.notifyPropertyChange(prop);
          _this.trigger('resize', evt);
        });
      });
    },
    _refreshPollLoop: function _refreshPollLoop() {
      var _this2 = this;

      PROPERTIES_TO_CHANGE_ON_INTERVAL.forEach(function (p) {
        next(function () {
          return _this2.notifyPropertyChange(p);
        });
      });
      this._rpid = this.get('w').requestAnimationFrame(function () {
        return _this2._refreshPollLoop();
      });
    },


    clientHeight: computed(function () {
      return this.get('w.document.documentElement.clientHeight') || 0;
    }).volatile(),
    clientWidth: computed(function () {
      return this.get('w.document.documentElement.clientWidth') || 0;
    }).volatile(),
    scrollTop: computed(function () {
      return this.get('w.document.documentElement.scrollTop') || this.get('w.document.body.scrollTop') || 0;
    }).volatile(),
    scrollLeft: computed(function () {
      return this.get('w.document.documentElement.scrollLeft') || this.get('w.document.body.scrollLeft') || 0;
    }).volatile(),
    scrollHeight: computed(function () {
      return this.get('w.document.documentElement.scrollHeight') || this.get('w.document.body.scrollHeight') || 0;
    }).volatile(),
    scrollWidth: computed(function () {
      return this.get('w.document.documentElement.scrollWidth') || this.get('w.document.body.scrollWidth') || 0;
    }).volatile(),

    scrollRight: computed('scrollLeft', 'scrollWidth', 'clientWidth', function () {
      return this.get('scrollWidth') - this.get('clientWidth') - this.get('scrollLeft');
    }),

    scrollBottom: computed('scrollTop', 'scrollHeight', 'clientHeight', function () {
      return this.get('scrollHeight') - this.get('clientHeight') - this.get('scrollTop');
    }),

    scrollHRatio: computed('scrollLeft', 'scrollWidth', 'clientWidth', function () {
      if (this.get('scrollWidth') === this.get('clientWidth')) {
        return 1;
      } else {
        return this.get('scrollLeft') / (this.get('scrollWidth') - this.get('clientWidth'));
      }
    }),
    scrollVRatio: computed('scrollTop', 'scrollHeight', 'clientHeight', function () {
      return this.get('scrollTop') / (this.get('scrollHeight') - this.get('clientHeight'));
    })
  };

  WINDOW_PROPERTIES.forEach(function (propInfo) {
    switch (typeOf(propInfo)) {
      case 'string':
        serviceCfg[propInfo] = computed(function () {
          return this.get('w.' + propInfo) || 0;
        });
        break;
      default:
        throw 'Invalid property value: ' + propInfo + ' of type ' + typeOf(propInfo);
    }
  });

  exports.default = Service.extend(Evented, serviceCfg);
});