define("@ember-decorators/argument/-private/utils/computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDescriptorTrap = isDescriptorTrap;
  _exports.isEmberDescriptor = isEmberDescriptor;
  _exports.isMandatorySetter = isMandatorySetter;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function isMandatorySetter(setter) {
    return setter && setter.toString().match('You must use .*set()') !== null;
  }

  /**
   * Recognizes objects that are Ember property descriptors
   *
   * @parom {object} maybeDesc
   * @return {boolean}
   */
  function isEmberDescriptor(maybeDesc) {
    return maybeDesc !== null && _typeof(maybeDesc) === 'object' && maybeDesc.isDescriptor;
  }
  function isDescriptorTrap(maybeDesc) {
    return maybeDesc !== null && _typeof(maybeDesc) === 'object' && !!maybeDesc.__DESCRIPTOR__;
  }
});