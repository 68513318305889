define('ember-intl/utils/array-to-hash', ['exports', 'ember-intl/utils/empty-object'], function (exports, _emptyObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  function arrayToHash(array) {
    var len = array.length;
    var out = new _emptyObject.default();
    var i = 0;

    for (; i < len; i++) {
      var key = array[i];
      out[key] = key;
    }

    return out;
  }

  exports.default = arrayToHash;
});