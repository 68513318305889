define('ember-intl/helpers/format-date', ['exports', 'ember-intl/helpers/-format-base'], function (exports, _formatBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formatBase.default.extend({
    allowEmpty: true,

    format: function format(value, options) {
      return this.intl.formatDate(value, options);
    }
  });
});