define('ember-intl/formatters/format-number', ['exports', 'intl-format-cache', 'ember-intl/formatters/-base'], function (exports, _intlFormatCache, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FormatNumber = _base.default.extend({
    formatter: Ember.computed({
      get: function get() {
        return (0, _intlFormatCache.default)(Intl.NumberFormat);
      }
    }).readOnly(),

    format: function format(value, options, ctx) {
      return this._format(value, this.filterSupporedOptions(options), undefined, ctx);
    }
  }); /**
       * Copyright 2015, Yahoo! Inc.
       * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
       */

  FormatNumber.reopenClass({
    formatType: 'number',
    supportedOptions: ['localeMatcher', 'style', 'currency', 'currencyDisplay', 'useGrouping', 'minimumIntegerDigits', 'minimumFractionDigits', 'maximumFractionDigits', 'minimumSignificantDigits', 'maximumSignificantDigits']
  });

  exports.default = FormatNumber;
});