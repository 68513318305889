define('ember-intl/helpers/intl-get', ['exports', 'ember-intl/helpers/l'], function (exports, _l) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var IntlGetHelper = Ember.Helper.extend({
    intl: Ember.inject.service(),

    init: function init() {
      this._super();

      Ember.deprecate('[ember-int] intl-get is deprecated, use {{t \'translation.key\'}}', false, {
        id: 'ember-intl-t-helper',
        until: '3.0.0'
      });

      Ember.get(this, 'intl').on('localeChanged', this, this.recompute);
    },
    compute: function compute(params) {
      var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return new _l.LiteralWrapper(Ember.get(this, 'intl').lookup(params[0], hash.locale));
    },
    willDestroy: function willDestroy() {
      this._super();

      Ember.get(this, 'intl').off('localeChanged', this, this.recompute);
    }
  }); /**
       * Copyright 2015, Yahoo! Inc.
       * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
       */

  exports.default = IntlGetHelper;
});