define('liquid-tether/index', ['exports', 'liquid-fire/constraint'], function (exports, _constraint) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.target = target;
  exports.onOpenTether = onOpenTether;
  exports.onCloseTether = onCloseTether;
  function target(name) {
    return new _constraint.default('parentElementClass', '' + name);
  }

  function onOpenTether() {
    return new _constraint.default('newValue', function (value) {
      return value !== null;
    });
  }

  function onCloseTether() {
    return new _constraint.default('newValue', function (value) {
      return value === null;
    });
  }
});