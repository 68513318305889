define('ember-composable-helpers/helpers/invoke', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.invoke = invoke;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  var all = Ember.RSVP.all;
  function invoke(_ref) {
    var _ref2 = _toArray(_ref),
        methodName = _ref2[0],
        args = _ref2.slice(1);

    var obj = args.pop();

    if (Ember.isArray(obj)) {
      return function () {
        var promises = obj.map(function (item) {
          return Ember.tryInvoke(item, methodName, args);
        });

        return all(promises);
      };
    }

    return function () {
      return Ember.tryInvoke(obj, methodName, args);
    };
  }

  exports.default = Ember.Helper.helper(invoke);
});