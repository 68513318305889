define('intl-format-cache/index', ['exports', 'intl-format-cache/memoizer'], function (exports, _memoizer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _memoizer.default;
    }
  });
});