define("@ember-decorators/argument/-private/validations-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addValidationFor = addValidationFor;
  _exports.getValidationsFor = getValidationsFor;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var VALIDATION_MAP = new WeakMap();

  /**
   * Recursively get all validations for the given class, and all parent classes
   */
  function getValidationsFor(klass) {
    var klassValidations = VALIDATION_MAP.get(klass) || {};
    var prototype = Object.getPrototypeOf(klass);
    var prototypeValidations = {};
    if (prototype) {
      prototypeValidations = getValidationsFor(prototype);
    }

    // Note: order is important to ensure that a subclass cannot override
    // the type of an argument
    return _objectSpread(_objectSpread({}, klassValidations), prototypeValidations);
  }
  function addValidationFor(klass, prop, validator) {
    var validators;
    if (VALIDATION_MAP.has(klass)) {
      validators = VALIDATION_MAP.get(klass);
    } else {
      validators = {};
    }
    validators[prop] = validator;
    VALIDATION_MAP.set(klass, validators);
  }
});