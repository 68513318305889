define('ember-intl/helpers/t', ['exports', 'ember-intl/helpers/-format-base'], function (exports, _formatBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getValue = getValue;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function getValue(_ref, options) {
    var _ref2 = _slicedToArray(_ref, 1),
        translationKey = _ref2[0];

    (true && !(translationKey) && Ember.assert('[ember-intl] translation lookup attempted but no translation key was provided.', translationKey));
    var fallback = options.fallback,
        allowEmpty = options.allowEmpty,
        defaultMessage = options.defaultMessage,
        optionalLocale = options.locale;

    var fallbackTranslation = defaultMessage || fallback;

    var translation = this.intl.lookup(translationKey, optionalLocale, {
      resilient: allowEmpty || typeof fallbackTranslation === 'string'
    });

    return typeof translation === 'string' ? translation : fallbackTranslation;
  }

  exports.default = _formatBase.default.extend({
    getValue: getValue,

    format: function format(value, options) {
      if (options && options.htmlSafe === true) {
        return this.intl.formatHtmlMessage(value, options);
      }

      return this.intl.formatMessage(value, options);
    }
  });
});