define('ember-accordion/components/accordion-list', ['exports', 'ember', 'ember-accordion/templates/components/accordion-list', 'ember-accordion/utils/item'], function (exports, _ember, _emberAccordionTemplatesComponentsAccordionList, _emberAccordionUtilsItem) {
  var isEqual = _ember['default'].isEqual;

  var AccordionListComponent = _ember['default'].Component.extend({
    layout: _emberAccordionTemplatesComponentsAccordionList['default'],
    classNames: ["AccordionList"],

    // Input params
    allowManyActiveItems: false,

    // Internal state
    _activeItems: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_activeItems', _ember['default'].A([]));
    },

    actions: {
      toggleItem: function toggleItem(itemId, panelName) {
        var activeItems = this.get('_activeItems');
        var targetItem = activeItems.findBy('id', itemId);

        // if target item is already active
        if (targetItem) {
          // and the panel is already active
          if (isEqual(targetItem.get('panel'), panelName)) {
            activeItems.removeObject(targetItem);
          } else {
            targetItem.set('panel', panelName);
          }
        } else {
          // if simultanious active items are not allow, clear array
          if (!this.get('allowManyActiveItems')) {
            activeItems.clear();
          }

          var newItem = _emberAccordionUtilsItem['default'].create({ id: itemId, panel: panelName });
          activeItems.addObject(newItem);
        }
      },

      closeItem: function closeItem(itemId) {
        var activeItems = this.get('_activeItems');
        var activeItem = activeItems.findBy('id', itemId);
        activeItems.removeObject(activeItem);
      }
    }
  });

  exports['default'] = AccordionListComponent;
});