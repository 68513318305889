define('ember-intl/formatters/format-html-message', ['exports', 'ember-intl/formatters/format-message'], function (exports, _formatMessage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Handlebars = Ember.Handlebars;


  var FormatHtmlMessage = _formatMessage.default.extend({
    escapeProps: function escapeProps(options) {
      if (!options) {
        return;
      }

      return Object.keys(options).reduce(function (result, hashKey) {
        var value = options[hashKey];

        if (typeof value === 'string') {
          value = Handlebars.Utils.escapeExpression(value);
        }

        result[hashKey] = value;

        return result;
      }, {});
    },
    format: function format(value, options, ctx) {
      var escapedOptions = this.escapeProps(options);
      var superResult = this._super(value, escapedOptions, ctx);

      return Ember.String.htmlSafe(superResult);
    }
  });

  FormatHtmlMessage.reopenClass({
    formatType: 'html-message'
  });

  exports.default = FormatHtmlMessage;
});