define('ember-intl/helpers/format-html-message', ['exports', 'ember-intl/helpers/-format-base', 'ember-intl/helpers/format-message'], function (exports, _formatBase, _formatMessage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _formatBase.default.extend({
    getValue: _formatMessage.getValue,
    helperType: 'format-html-message',

    format: function format(value, options) {
      return this.intl.formatHtmlMessage(value, options);
    }
  });
});