define("@ember-decorators/argument/index", ["exports", "@ember-decorators/argument/-private/resolve-validator", "@ember-decorators/argument/-private/validations-for", "@ember-decorators/argument/-private/extensions/with-validation", "@ember-decorators/argument/-private/extensions/prevent-additional-arguments"], function (_exports, _resolveValidator, _validationsFor, _withValidation, _preventAdditionalArguments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argument = argument;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function argument(typeDefinition) {
    (true && !(typeof typeDefinition !== 'undefined') && Ember.assert('A type definition must be provided to `@argument`', typeof typeDefinition !== 'undefined'));
    (true && !(typeDefinition.toString() !== '[object Descriptor]') && Ember.assert('`@argument` must be passed a type to validate against', typeDefinition.toString() !== '[object Descriptor]'));
    (true && !(arguments.length === 1) && Ember.assert('`@argument` must only be passed one type definition', arguments.length === 1));
    var validator = (0, _resolveValidator.default)(typeDefinition);
    return function (desc) {
      return _objectSpread(_objectSpread({}, desc), {}, {
        finisher: function finisher(klass) {
          (0, _validationsFor.addValidationFor)(klass, desc.key, validator);
          if (!(0, _withValidation.hasExtension)(klass)) {
            klass = (0, _withValidation.withExtension)(klass);
          }
          if (!(0, _preventAdditionalArguments.hasExtension)(klass) && (0, _preventAdditionalArguments.needsExtension)(klass)) {
            klass = (0, _preventAdditionalArguments.withExtension)(klass);
          }
          return klass;
        }
      });
    };
  }
});