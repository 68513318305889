define('ember-accordion/components/accordion-toggle', ['exports', 'ember', 'ember-accordion/templates/components/accordion-toggle'], function (exports, _ember, _emberAccordionTemplatesComponentsAccordionToggle) {
  var computed = _ember['default'].computed;
  var isEqual = _ember['default'].isEqual;

  var AccordionToggleComponent = _ember['default'].Component.extend({
    layout: _emberAccordionTemplatesComponentsAccordionToggle['default'],
    classNames: ["AccordionToggle"],
    classNameBindings: ['isActive', 'disabled'],

    // Input params
    disabled: null,
    panelName: 'panel-one',

    isActive: computed('activePanelName', 'panelName', function () {
      return isEqual(this.get('activePanelName'), this.get('panelName'));
    }),

    click: function click() {
      if (!this.get('disabled')) {
        this.get('toggle')(this.get('panelName'));
      }
      return false;
    }
  });

  AccordionToggleComponent.reopenClass({
    positionalParams: ['activePanelName', 'toggle']
  });

  exports['default'] = AccordionToggleComponent;
});