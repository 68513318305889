define('memory-scroll/components/remember-document-scroll', ['exports', 'memory-scroll/components/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    targetElement: function targetElement() {
      return document.scrollingElement || // Covers every modern browser
      document.documentElement; // Covers IE
    }
  });
});