define('memory-scroll/components/scroll-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var FIRST_RUN = function FIRST_RUN() {};

  var Component = Ember.Component;
  exports.default = Component.extend({
    init: function init() {
      this._lastKey = FIRST_RUN;
      this._super.apply(this, arguments);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var key = this.get('key');
      if (key !== this._lastKey) {
        this._lastKey = key;
        (document.scrollingElement || document.documentElement).scrollTop = this.get('position') || 0;
      }
    }
  });
});