define("@ember-decorators/argument/-private/utils/object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPropertyDescriptor = getPropertyDescriptor;
  _exports.isExtensionOf = isExtensionOf;
  /**
   * Walk up the prototype chain and find the property descriptor for the
   * given property
   *
   * @param {object} target
   * @param {string} property
   * @return {Descriptor|undefined}
   */
  function getPropertyDescriptor(target, property) {
    if (target === undefined || target === null) return;
    return Object.getOwnPropertyDescriptor(target, property) || getPropertyDescriptor(Object.getPrototypeOf(target), property);
  }
  function isExtensionOf(childClass, parentClass) {
    return childClass.prototype instanceof parentClass;
  }
});