define('ember-accordion/components/accordion-panel', ['exports', 'ember', 'ember-accordion/templates/components/accordion-panel'], function (exports, _ember, _emberAccordionTemplatesComponentsAccordionPanel) {
  var computed = _ember['default'].computed;
  var isEqual = _ember['default'].isEqual;

  var AccordionPanelComponent = _ember['default'].Component.extend({
    layout: _emberAccordionTemplatesComponentsAccordionPanel['default'],
    classNames: ["AccordionPanel"],
    classNameBindings: ['isActive'],

    // Inputs
    panelName: 'panel-one',

    isActive: computed('activePanelName', 'panelName', function () {
      return isEqual(this.get('activePanelName'), this.get('panelName'));
    }),
    openOnInit: false,
    _activateDefaultPanel: function _activateDefaultPanel() {
      if (this.isDestroying) {
        return;
      }

      if (this.get('openOnInit')) {
        this.get('toggle')(this.get('panelName'));
      }
    },

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      _ember['default'].run.next(function () {
        _this._activateDefaultPanel();
      });
    }
  });

  AccordionPanelComponent.reopenClass({
    positionalParams: ['activePanelName', 'toggle']
  });

  exports['default'] = AccordionPanelComponent;
});