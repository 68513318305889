define('ember-intl/formatters/format-time', ['exports', 'ember-intl/formatters/format-date'], function (exports, _formatDate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FormatTime = _formatDate.default.extend(); /**
                                                  * Copyright 2015, Yahoo! Inc.
                                                  * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
                                                  */

  FormatTime.reopenClass({
    formatType: 'time'
  });

  exports.default = FormatTime;
});