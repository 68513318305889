define('intl-format-cache/memoizer', ['exports', 'intl-format-cache/es5'], function (exports, _es) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    exports.default = createFormatCache;


    // -----------------------------------------------------------------------------

    function createFormatCache(FormatConstructor) {
        var cache = (0, _es.objCreate)(null);

        return function () {
            var args = Array.prototype.slice.call(arguments);
            var cacheId = getCacheId(args);
            var format = cacheId && cache[cacheId];

            if (!format) {
                format = new (_es.bind.apply(FormatConstructor, [null].concat(args)))();

                if (cacheId) {
                    cache[cacheId] = format;
                }
            }

            return format;
        };
    }

    // -- Utilities ----------------------------------------------------------------

    function getCacheId(inputs) {
        // When JSON is not available in the runtime, we will not create a cache id.
        if (typeof JSON === 'undefined') {
            return;
        }

        var cacheId = [];

        var i, len, input;

        for (i = 0, len = inputs.length; i < len; i += 1) {
            input = inputs[i];

            if (input && (typeof input === 'undefined' ? 'undefined' : _typeof(input)) === 'object') {
                cacheId.push(orderedProps(input));
            } else {
                cacheId.push(input);
            }
        }

        return JSON.stringify(cacheId);
    }

    function orderedProps(obj) {
        var props = [],
            keys = [];

        var key, i, len, prop;

        for (key in obj) {
            if (obj.hasOwnProperty(key)) {
                keys.push(key);
            }
        }

        var orderedKeys = keys.sort();

        for (i = 0, len = orderedKeys.length; i < len; i += 1) {
            key = orderedKeys[i];
            prop = {};

            prop[key] = obj[key];
            props[i] = prop;
        }

        return props;
    }
});