define('intl-messageformat/main', ['exports', 'intl-messageformat/core', 'intl-messageformat/en'], function (exports, _core, _en) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* jslint esnext: true */

  _core.default.__addLocaleData(_en.default);
  _core.default.defaultLocale = 'en';

  exports.default = _core.default;
});